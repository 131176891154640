const STACK_MAP = {
  '/sign-in': 1,
  '/sign-up': 1,
  '/sign-up-update': 1,
  '/sign-out': 1,
  '/reset-password': 1,
  '/get-profile': 1,
  '/get-profile-extended': 1,
  '/confirm-account': 1,
  '/update-settings': 1,
  '/edit-profile': 1,
  '/verify-profile': 1,
  '/admin-get-tasks': 1,
  '/admin-get-users': 1,
  '/admin-get-deals': 1,
  '/delete-investor': 1,
  '/admin-set-kyc': 1,
  '/get-deals': 1,
  '/add-deal': 1,
  '/update-with-s3': 1,
  '/get-documents': 1,
  '/admin-get-documents': 1,
  '/get-notifications': 1,
  '/add-to-watch': 1,
  '/sign-doc': 1,
  '/get-deal': 1,
  '/get-deal-user': 1,
  '/get-user': 1,
  '/delete-company-from-portfolio': 1,
  '/get-investment': 1,
  '/update-spv-extended': 1,

  '/add-admin': 2,
  '/notify': 2,
  '/register-investment': 2,
  '/register-interest': 2,
  '/delete-account': 2,
  '/set-deal-status': 2,
  '/edit-decline-comment': 2,
  '/admin-delete-account': 2,
  '/admin-update-deal': 2,
  '/compliance-update-deal': 2,
  '/add-to-portfolio': 2,
  '/add-investor': 2,
  '/modify-investment': 2,
  '/get-watch-list': 2,
  '/deactivate-notifications': 2,
  '/delete-retained': 2,
  '/get-spv': 2,
  '/create-spv': 2,
  '/get-spv-admin': 2,
  '/set-spv-status': 2,
  '/get-spv-investors': 2,
  '/update-spv-investors': 2,
  '/update-spv-doc-status': 2,
  '/notify-investors': 2,

  '/delete-deal': 3,
  '/notify-user': 3,
  '/delete-doc-from-deal': 3,
  '/edit-date': 3,
  '/edit-fee': 3,
  '/edit-return': 3,
  '/delete-id-from-profile': 3,
  '/set-tutor': 3,
  '/rm-watch': 3,
  '/type-forms': 3,
  '/stats': 3,
  '/manage-pass': 3,
  '/attach-iot-policy': 3,
  '/manual-confirm': 3,
  '/gsi': 3,

  '/front-errors': 4,
  '/anon-errors': 4,

  '/invoker': 5
}

export default STACK_MAP

// eslint-disable-next-line
const VERSION = '2'

// eslint-disable-next-line
const URLS = {
  1: {
    dev: { 2: '0e62cazj2f' },
    stag: { 2: '3g2c5a770f' },
    prod: { 2: '4xrggk15y9' }
  },
  2: {
    dev: { 2: 'wifrgz9pkj' },
    stag: { 2: '35koyxvlpk' },
    prod: { 2: '0v22lsggs4' }
  },
  3: {
    dev: { 2: 'x7v1tkmhq4' },
    stag: { 2: 'adw0871n6k' },
    prod: { 2: 'x4plw86a7h' }
  },
  // utils
  4: {
    dev: { 2: '8h6mo9x42c' },
    stag: { 2: 'mn7juwtao7' },
    prod: { 2: 'mkuyba6zof' }
  },
  // pdf
  5: {
    dev: { 2: '6dyu8tymj5' },
    stag: { 2: '55mvylw4y6' },
    prod: { 2: '3dfpv02xgc' }
  }
}

export const url = (stack) => {
  return `https://api${stack}.shareline.net/${process.env.stage}`
  // const apiId = URLS[stack][process.env.stage][VERSION]
  // return `https://${apiId}.execute-api.eu-west-2.amazonaws.com/${process.env.stage}`
}
