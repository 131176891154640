import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'tasks',
  initialState: adapter.getInitialState(),
  reducers: {
    setTask: (state, { payload }) => adapter.upsertOne(state, payload),
    removeTask: (state, { payload }) => adapter.removeOne(state, payload),
    setTasks: (state, { payload }) => adapter.setAll(state, payload),
    removeAllTasks: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setTask, removeTask, setTasks, removeAllTasks } = slice.actions

export default slice.reducer
