import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const dealsSlice = createSlice({
  name: 'initModal',
  initialState,
  reducers: {
    setInitModal: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setInitModal } = dealsSlice.actions

export default dealsSlice.reducer
