import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    shouldBPro: false
  }
}

export const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setModal } = slice.actions

export default slice.reducer
