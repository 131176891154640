import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'kycDetails',
  initialState,
  reducers: {
    setKycDetails: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setKycDetails } = slice.actions

export default slice.reducer
