import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import TopBarProfile from 'components/layouts/header/profile'
import SuperTitle from 'components/layouts/header/sTitle'
import { HEADER_CONTAINER } from 'theme/theme'

const HeaderContainer = styled.header`
  ${HEADER_CONTAINER}
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 767px) {
    flex-direction: column;
    flex: auto;
  }
`

const HeaderActions = styled.div`
  display: flex;
  padding-top: 3px;
`

const BurgerButton = styled.span`
  display: flex;
  width: 28px;
  height: 23px;
  background-image: url('/s/icons/burger.svg');
  background-size: 100% 100%;
  cursor: pointer;
`

const BurgerWrapper = styled.div`
  display: none;
  flex: 1;
  margin-top: 5px;

  @media (max-width: 767px) {
    display: flex;
  }
`

export default function Header ({ sTitle, withBack, children, noProfile, onShowSidebar, backUrl, Icon, InvestButtons }) {
  const showSidebar = () => onShowSidebar(true)

  return (
    <HeaderContainer>
      { noProfile
        ? null
        : <BurgerWrapper>
            <BurgerButton onClick={showSidebar} data-test="burger" />
          </BurgerWrapper> }
      <TitleWrapper>
        <SuperTitle title={sTitle} isBack={withBack} backUrl={backUrl} Icon={Icon} />
        <HeaderActions>{ children || null }</HeaderActions>
      </TitleWrapper>
      { noProfile
        ? null
        : <TopBarProfile InvestButtons={InvestButtons} /> }
    </HeaderContainer>
  )
}

Header.propTypes = {
  sTitle: PropTypes.string,
  Icon: PropTypes.any,
  InvestButtons: PropTypes.any,
  withBack: PropTypes.bool,
  backUrl: PropTypes.string,
  noProfile: PropTypes.bool,
  children: PropTypes.any,
  onShowSidebar: PropTypes.any
}
