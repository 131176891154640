import { isLocal, isCloudFront } from '.config'

export const GA_TRACKING_ID = 'G-V1054CMG4H'

export const pageView = (url, log) => {
  log({ category: 'page-view', message: url })
  if (!isLocal() && !(process.env.stage === 'dev') && !(process.env.stage === 'stag') && !isCloudFront()) {
    try {
      window.gtag('config', GA_TRACKING_ID, {
        page_path: url
      })
    } catch (e) {
      console.error('pageView', url, e)
    }
  }
}

const EVENTS = {
  click_sign_in_business: { category: 'Sign In', label: 'Investor', action: 'login', value: 1 },
  click_sign_in_investor: { category: 'Sign In', label: 'Business', action: 'login', value: 1 },

  click_sign_up_business: { category: 'Sign Up', label: 'Investor', action: 'register', value: 1 },
  click_sign_up_investor: { category: 'Sign Up', label: 'Business', action: 'register', value: 1 },

  invested: { category: 'KPI', label: 'Invested', action: 'invested', value: 10 },
  scheduled_call: { category: 'KPI', label: 'Scheduled call', action: 'scheduled_call', value: 10 },

  view_deal: { category: 'KPI', label: 'View deal', action: 'view_deal', value: 3 },
  open_founder_form: { category: 'KPI', label: 'Opens founder gorm', action: 'founder_form', value: 1 },
  open_empl_form: { category: 'KPI', label: 'Opens employee form', action: 'employee_form', value: 1 },
  finish_founder_form: { category: 'KPI', label: 'Finishes founder gorm', action: 'founder_form_finish', value: 5 },
  finish_empl_form: { category: 'KPI', label: 'Finishes employee form', action: 'employee_form_finish', value: 5 }
}

export const regEvent = (t) => {
  try {
    if (!(process.env.stage === 'dev') && !(process.env.stage === 'stag') && !isLocal() && !isCloudFront()) {
      window.gtag('event', EVENTS[t].action, {
        event_category: EVENTS[t].category,
        event_label: EVENTS[t].label,
        value: EVENTS[t].value
      })
    }
  } catch (e) {
    console.error('regEvent', t, e)
  }
}
