import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const dealsSlice = createSlice({
  name: 'foundersForm',
  initialState,
  reducers: {
    setFoundersForm: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setFoundersForm } = dealsSlice.actions

export default dealsSlice.reducer
