import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const dealsSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    setTest: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setTest } = dealsSlice.actions

export default dealsSlice.reducer
