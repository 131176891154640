import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const dealsSlice = createSlice({
  name: 'verifyProfileCompany',
  initialState,
  reducers: {
    setVerifyProfileCompany: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setVerifyProfileCompany } = dealsSlice.actions

export default dealsSlice.reducer
