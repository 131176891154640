import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import { combineReducers } from 'redux'
// import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'

import modal from 'store/actions/modal'
import enter from 'store/actions/enter'
import adminDeals from 'store/actions/adminDeals'
import investorDeals from 'store/actions/investorDeals'
import businessDeals from 'store/actions/businessDeals'
import adminDocuments from 'store/actions/adminDocuments'
import user from 'store/actions/user'
import notifications from 'store/actions/notifications'
import derivatives from 'store/actions/derivatives'
import approveDealTeam from 'store/actions/approveDealTeam'
import approveDealDocuments from 'store/actions/approveDealDocuments'
import documents from 'store/actions/documents'
import dealFlow from 'store/actions/dealFlow'
import users from 'store/actions/users'
import admins from 'store/actions/admins'
import tasks from 'store/actions/tasks'
import adminInterests from 'store/actions/adminInterests'
import adminInvestments from 'store/actions/adminInvestments'
import businessInvestments from 'store/actions/businessInvestments'
import investorInvestments from 'store/actions/investorInvestments'
import investors from 'store/actions/investors'
import businesses from 'store/actions/businesses'
import watchList from 'store/actions/watchList'
import addDealDocuments from 'store/actions/addDealDocuments'
import signUp from 'store/actions/signUp'
import stats from 'store/actions/stats'
import page from 'store/actions/page'
import schedule from 'store/actions/schedule'
import email from 'store/actions/email'
import message from 'store/actions/message'
import initModal from 'store/actions/initModal'
import adminPerformance from 'store/actions/adminPerformance'
import investorPerformance from 'store/actions/investorPerformance'
import verifyProfile from 'store/actions/verifyProfile'
import questionnaire from 'store/actions/questionnaire'
import proQuestionnaire from 'store/actions/proQuestionnaire'
import singapore from 'store/actions/singapore'
import verifyProfileCompany from 'store/actions/verifyProfileCompany'
import test from 'store/actions/test'
import regulated from 'store/actions/regulated'
import regulatedIn from 'store/actions/regulatedIn'
import regulatedStatus from 'store/actions/regulatedStatus'
import foundersForm from 'store/actions/foundersForm'
import dealDocuments from 'store/actions/dealDocuments'
import kycDetails from 'store/actions/kycDetails'
import task from 'store/actions/task'
import tempData from 'store/actions/tempData'
import dealWarning from 'store/actions/dealWarning'
import upload from 'store/actions/upload'
import tutorial from 'store/actions/tutorial'
import jobs from 'store/actions/jobs'
import adminSpv from 'store/actions/adminSpv'
import spv from 'store/actions/spv'
import bankAdmin from 'store/actions/bankAdmin'
import memberDocument from 'store/actions/memberDocument'
import createSpv from 'store/actions/createSpv'
import spvInvestor from 'store/actions/spvInvestor'
import adminSpvInvestor from 'store/actions/adminSpvInvestor'
import investorSpv from 'store/actions/investorSpv'
import userExtended from 'store/actions/userExtended'
import count from 'store/actions/count'
// import { createStateSyncMiddleware } from './utils/syncState'
import { DEV } from '.config'
import storageInstance from './utils/localForage'
// import encryptTransform from './utils/encrypt'
// import createIdbStorage from './utils/idbStorage'
const listenerMiddleware = createListenerMiddleware()
// export const storage = createIdbStorage({ name: `shareline-v10.4-${process.env.stage}`, storeName: 'shareline' })
export const storage = storageInstance(`shareline-v11-${process.env.stage}`)

const MAIN_CONFIG = DEV
  ? {
      key: `root${process.env.stage}-dev`,
      version: 1,
      asyncTransforms: true
    }
  : {
      key: `root${process.env.stage}`,
      version: 1,
      storage,
      blackList: ['enter', 'task']/* ,
      transforms: [
        encryptTransform({
          secretKey: process.env.enc,
          iv: process.env.iv,
          onError: function (error) {
            console.error(error)
          }
        })
      ],
      asyncTransforms: true
      */
    }

const rootReducer = combineReducers({
  // ums
  user,
  userExtended,
  notifications,
  documents,
  watchList,
  signUp,
  adminPerformance,
  investorPerformance,

  // kyc
  verifyProfile,
  questionnaire,
  proQuestionnaire,
  singapore,
  verifyProfileCompany,
  test,
  regulated,
  regulatedIn,
  regulatedStatus,
  kycDetails,

  // utils
  modal,
  enter,
  email,
  message,
  initModal,
  page,
  foundersForm,
  task,
  tempData,
  upload,
  tutorial,
  dealWarning,
  jobs,
  count,

  // investor
  investorDeals,
  dealFlow,
  investorInvestments,
  dealDocuments,
  schedule,

  // business
  businessDeals,
  businessInvestments,
  addDealDocuments,

  // admin
  adminDeals,
  adminDocuments,
  derivatives,
  approveDealTeam,
  approveDealDocuments,
  users,
  admins,
  tasks,
  adminInterests,
  adminInvestments,
  investors,
  businesses,
  stats,

  // spv
  spv,
  adminSpv,
  bankAdmin,
  memberDocument,
  createSpv,
  spvInvestor,
  adminSpvInvestor,
  investorSpv
})

const _reducer = (state, action) => {
  if (action.type === 'RESET') {
    return rootReducer(undefined, action)
  }
  return rootReducer(state, action)
}

const reducer = DEV ? _reducer : persistReducer(MAIN_CONFIG, _reducer)

const makeStore = () => {
  return DEV
    ? configureStore({
      reducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
        /*
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ]
        */
      })
    })
    : configureStore({
      reducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
        .prepend(listenerMiddleware.middleware)
        /*
        .concat(createStateSyncMiddleware({
          blacklist: [
            'persist/PERSIST',
            'persist/REHYDRATE',
            'persist/FLUSH',
            'persist/PAUSE',
            'persist/PURGE',
            'persist/DEFAULT_VERSION'
            // ...BLACKLIST
          ]
        }))
      */
    })
}

const store = makeStore()

const persistor = DEV ? null : persistStore(store)

function swapStore (userId) {
  const newConfig = {
    ...MAIN_CONFIG,
    keyPrefix: userId
  }
  store.replaceReducer(persistReducer(newConfig, rootReducer))
}

export { store, persistor, swapStore }
