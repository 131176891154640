import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Provider, useSelector } from 'react-redux'
import Script from 'next/script'
import useErrorBoundary from 'use-error-boundary'
import 'indexeddb-getall-shim'
import { install } from 'resize-observer'
import dynamic from 'next/dynamic'
import { PersistGate } from 'redux-persist/integration/react'
import { useRouter } from 'next/router'

import { GA_TRACKING_ID } from 'lib/gtag'
import { DEV } from '.config'
// import 'theme/calendar.scss'
import 'theme/style.css'
import 'theme/password.scss'
import { store, persistor } from 'store'
import useRedirect from 'utils/hooks/useRedirect'
import { register } from 'utils/sw'
import checker from 'utils/hooks/utils/publicCheck'
import { MicroLayout } from 'components/layouts/main'
import { initMessageListener } from 'store/utils/syncState'
import useApi from 'utils/fetch'
import Loader from 'components/utils/loaders/pageLoader' // not via dynamic for faster load
const Wrapped = dynamic(() => import('components/app'), { ssr: false })

export default function App ({ Component, pageProps, err }) {
  const noRender = false

  const responsiveHandler = () => {
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    document.documentElement.style.setProperty('--vh', `${vh * 0.01}px`)
    document.documentElement.style.setProperty('--vw', `${vw * 0.01}px`)
  }

  useEffect(() => {
    install()
    initMessageListener(store)
    // add responsive css width and height values to
    window.addEventListener('resize', responsiveHandler)
    register()
    // ServiceWorkerUnregister()

    return () => window.removeEventListener('resize', responsiveHandler)
  }, [])

  if (noRender) return null

  return (
    <>
      <Provider store={store}>
        { DEV
          ? <Managed Component={Component} pageProps={pageProps} err={err} />
          : <PersistGate loading={null} persistor={persistor}>
              <Managed Component={Component} pageProps={pageProps} err={err} />
            </PersistGate> }
      </Provider>
      { process.env.stage === 'prod' && <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} /> }
      { /* <Script src="https://accounts.google.com/gsi/client" /> */ }
      { process.env.stage === 'prod' && <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `
        }} /> }
    </>
  )
}

App.propTypes = {
  Component: PropTypes.any,
  err: PropTypes.any,
  pageProps: PropTypes.object
}

function Managed ({ Component, pageProps, err }) {
  const { ErrorBoundary, didCatch, error } = useErrorBoundary()
  const router = useRouter()
  const check = checker(router.pathname)
  const isSignIn = router.pathname === '/sign-in'
  const isHome = router.pathname === '/' || check
  const tos = router.pathname === '/tos'
  const privacy = router.pathname === '/privacy-policy'
  const risk = router.pathname === '/risk-warnings'
  const user = useSelector((state) => state.user.value)
  const tokenId = user?.tokenId
  const loading = !DEV && !tokenId && !isHome && !isSignIn && !tos && !privacy && !risk
  const { API } = useApi()
  useRedirect(loading, check)

  useEffect(() => {
    if (didCatch) {
      console.error(error?.message)
      API({ err: `didCatch\nstage: ${process.env.stage}\npathname: ${window?.location?.pathname}\nerr: ${error?.message}` })('anonReporting', false, null, true)
    }
  }, [didCatch, error?.message])

  if (loading) {
    return (
      <MicroLayout>
        <Loader />
      </MicroLayout>
    )
  }

  if (didCatch) return <p>An error has been caught and admin notified</p>

  return (
    <ErrorBoundary>
      <Component {...pageProps} err={err} />
      <Wrapped />
    </ErrorBoundary>
  )
}

Managed.propTypes = {
  Component: PropTypes.any,
  err: PropTypes.any,
  pageProps: PropTypes.object
}
