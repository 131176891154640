import React, { memo } from 'react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

const Back = styled.div`
  width: 20px;
  height: 20px;
  background: #efeff2;
  border-radius: 5px;
  margin-right: 20px;
  cursor: pointer;

  @media (max-width: 767px) {
    margin-left: -20px;
  }
`

const Img = styled.img`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 6.5px;
  margin-left: 6.5px;
`

function BackButton ({ backUrl }) {
  const router = useRouter()
  const back = () => backUrl
    ? router.push(backUrl)
    : router.back()

  return (
    <Back onClick={back} data-test="backButton">
      <Img src="/s/icons/back.svg" />
    </Back>
  )
}

BackButton.propTypes = {
  backUrl: PropTypes.string
}

export default memo(BackButton)
