import React from 'react'
import styled from '@emotion/styled'

const Wrap = styled.div`
  border: 10px solid #f3f3f3;
  border-top: 10px solid #0bf;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinloader 1s linear infinite;
  opacity: 0.5;

  img {
    height: 100px;
    width: 100px;
    animation: spinlogo 1s linear infinite;
  }

  @keyframes spinloader {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes spinlogo {
    0% { transform: rotate(360deg); }
    100% { transform: rotate(0deg); }
  }
`

const Center = styled.div`
  height: 110px;
  width: 110px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
`

const Loader = () => (
  <Center>
    <Wrap>
      <img src="/s/images/120x120.png" width="110px" height="110px" alt="Shareline" />
    </Wrap>
  </Center>
)

export default Loader
