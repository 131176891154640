import { Hub } from 'aws-amplify'

import parser from './parser'

const fetcher = async ({ fullUrl, options, dispatch, throwOnError }) => {
  const res = await fetch(fullUrl, options)
    .catch((err) => {
      if (err?.name === 'AbortError') {
        console.error('AbortError')
        return false
      }

      if (err?.message === 'Failed to fetch') {
        return {
          error: process.env.stage === 'dev'
            ? `${fullUrl}: Poor internet connection, please try again`
            : 'Poor internet connection, please try again'
        }
      } else {
        console.error(`fetcher ${fullUrl}\n\n${err?.message}`)
        return { error: err?.message }
      }
    })

  if (res?.error) return { error: res.error, response: null }
  else if (res) {
    const text = await res.text()
    const response = parser(text)
    if (typeof response?.error === 'string') {
      if (response.error.includes('JWTExpired') || response.error.includes('exp') || response.error.includes('session expired')) {
        Hub.dispatch('events', { event: 'sendRequest', action: 'signOut', body: { tokenId: null } })
        return { response: null, error: null }
      } else return { response, error: response.error }
    } else if (typeof response?.message === 'string') {
      return { error: response.message, response: null }
    } else if (response?.error) {
      return { response: null, error: 'Request failed and admin was informed, please try again later' }
    } else if (response) {
      return { response, error: null }
    }
  } else {
    if (throwOnError) {
      /*
      Hub.dispatch('events', {
        event: 'errorCount',
        action: 'add',
        body: { key }
      })
      */
      throw new Error('AbortError')
    }
    return { response: null, error: null }
  }
}

export default fetcher
