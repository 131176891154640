export default function RandomColor (color1, color2) {
  const registers = {
    hex3: /^#([a-f\d])([a-f\d])([a-f\d])$/i,
    hex6: /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i,
    rgb: /^rgb\s*\(\s*([\d.]+%?)\s*,\s*([\d.]+%?)\s*,\s*([\d.]+%?)\s*\)$/
  }

  const _obj1 = getValues(color1)
  const _obj2 = getValues(color2)

  function getValues (color) {
    let values = false

    for (const prop in registers) {
      if (registers[prop].test(color)) {
        values = {}
        values.r = color.replace(registers[prop], '$1')
        values.g = color.replace(registers[prop], '$2')
        values.b = color.replace(registers[prop], '$3')

        if (prop === 'rgb') {
          values.r = Number(values.r)
          values.g = Number(values.g)
          values.b = Number(values.b)
        } else {
          values.r = parseInt(values.r, 16)
          values.g = parseInt(values.g, 16)
          values.b = parseInt(values.b, 16)
        }

        break
      }
    }

    return values
  }

  function stripPad (text, padLength, padString, padType) {
    const length = padLength - text.length
    if (length < 0) { return text }
    const pad = new Array(length + 1).join(padString)
    if (padType === 'STR_PAD_LEFT') { return pad + text }
    return text + pad
  }

  function getRandom (c1, c2, percent) {
    let color = c1 + Math.floor((c2 - c1) * percent)

    if (color < 0) color = 0

    return stripPad(color.toString(16), 2, '0', 'STR_PAD_LEFT')
  }

  this.getColor = function () {
    if (_obj1 && _obj2) {
      const random = Math.random()
      const r = getRandom(_obj1.r, _obj2.r, random)
      const g = getRandom(_obj1.g, _obj2.g, random)
      const b = getRandom(_obj1.b, _obj2.b, random)

      return `#${r}${g}${b}`
    }

    return false
  }
}

export function invertColor (hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }

  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16)
  const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16)
  const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16)

  return `#${padZero(r)}${padZero(g)}${padZero(b)}`
}

function padZero (text, length) {
  length = length || 2
  const zeros = new Array(length).join('0')
  return (zeros + text).slice(-length)
}

export function shadeColor (color, percent) {
  let R = parseInt(color.substring(1, 3), 16)
  let G = parseInt(color.substring(3, 5), 16)
  let B = parseInt(color.substring(5, 7), 16)

  R = parseInt(R * (100 + percent) / 100)
  G = parseInt(G * (100 + percent) / 100)
  B = parseInt(B * (100 + percent) / 100)

  R = (R < 255) ? R : 255
  G = (G < 255) ? G : 255
  B = (B < 255) ? B : 255

  const RR = ((R.toString(16).length === 1) ? '0' + R.toString(16) : R.toString(16))
  const GG = ((G.toString(16).length === 1) ? '0' + G.toString(16) : G.toString(16))
  const BB = ((B.toString(16).length === 1) ? '0' + B.toString(16) : B.toString(16))

  return `#${RR}${GG}${BB}`
}

export const rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}
