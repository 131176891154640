import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'investorInvestments',
  initialState: adapter.getInitialState(),
  reducers: {
    setInvestorInvestment: (state, { payload }) => adapter.setOne(state, payload),
    removeInvestorInvestment: (state, { payload }) => adapter.removeOne(state, payload),
    setInvestorInvestments: (state, { payload }) => adapter.setAll(state, payload),
    setManyInvestorInvestments: (state, { payload }) => adapter.setMany(state, payload),
    investorInvestmentsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setInvestorInvestment, setInvestorInvestments, setManyInvestorInvestments, removeInvestorInvestment, investorInvestmentsRemoveAll } = slice.actions

export default slice.reducer
