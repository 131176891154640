import { css } from '@emotion/react'

/* content */

export const MAIN_WRAPPER = css`
  padding: 40px;
  padding-left: calc(250px + 40px);
  width: 1060px;

  @media (max-width: 1199px) {
    padding: 32px 18px;
    padding-left: calc(250px + 18px);
  }

  @media (max-width: 767px) {
    padding: 30px 12px;
  }
`

export const MAIN_BOX = css`
  position: absolute;
  width: 1020px;
  top: 110px;
  left: 290px;
  margin-bottom: 150px;
`

export const HEADER_CONTAINER = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 1199px) {
    margin-bottom: 20px;
    height: unset;
  }

  @media (max-width: 767px) {
    align-items: flex-start;
  }
`

export const CB = css`
  max-width: 1060px;
  width: 100%;
  padding: 20px 40px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 100vw;
    margin-left: -12px;
    margin-right: -12px;
    border-radius: 0;
    padding: 20px;
  }
`

export const DIVIDER = css`
  display: flex;
  width: 100%;
  border-top: 1px solid #f9f9fc;
  margin: 20px 0;
`

/* POPPINS */

const POPPINS = css`
  font-family: Poppins;
`
// ${secondary.style.fontFamily}

const RALE = css`
  font-family: Raleway;
`
// ${primary.style.fontFamily}

export const TYPOGRAPHY_9 = css`
  ${POPPINS}

  font-weight: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 13px;
`

export const TYPOGRAPHY_12 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
`

export const TYPOGRAPHY_8_12 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
`

export const TYPOGRAPHY_11_16 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`

export const TYPOGRAPHY_15 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
`

export const TYPOGRAPHY_15_12 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
`

export const TYPOGRAPHY_16 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`

export const TYPOGRAPHY_18_13 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
`

export const TYPOGRAPHY_18 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
`

export const TYPOGRAPHY_18_500 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`

export const TYPOGRAPHY_19 = css`
  ${POPPINS}

  font-size: 13px;
  line-height: 19px;
`

export const TYPOGRAPHY_19_500 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
`

export const TYPOGRAPHY_20 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
`

export const TYPOGRAPHY_21 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`

export const TYPOGRAPHY_21_500 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`

export const TYPOGRAPHY_24 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`

export const TYPOGRAPHY_24_400 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

`

export const TYPOGRAPHY_27 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
`

export const TYPOGRAPHY_27_300 = css`
  ${POPPINS}

  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
`

export const TYPOGRAPHY_27_500 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
`

export const TYPOGRAPHY_16_30 = css`
  ${POPPINS}

  font-weight: bold;
  font-size: 16px;
  line-height: 30px;
`

export const TYPOGRAPHY_30 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
`

export const TYPOGRAPHY_36 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 30px;
  }
`

export const TYPOGRAPHY_40_400 = css`
  ${POPPINS}

  font-weight: 400;
  font-size: 15px;
  line-height: 40px;
`

export const TYPOGRAPHY_40_500 = css`
  ${POPPINS}

  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
`

export const TYPOGRAPHY_58 = css`
  ${POPPINS}

  font-weight: normal;
  font-size: 46px;
  line-height: 58px;
`

export const TYPOGRAPHY_108_600 = css`
  ${POPPINS}

  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
`

/* RALEWAY */

export const RALEWAY_14 = css`
  ${RALE}

  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`

export const RALEWAY_16 = css`
  ${RALE}

  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`

export const RALEWAY_21 = css`
  ${RALE}

  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
`

export const RALEWAY_42 = css`
  ${RALE}

  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
`

export const RALEWAY_63_800 = css`
  ${RALE}

  font-weight: 800;
  font-size: 54px;
  line-height: 63px;
`
