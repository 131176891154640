import Const, { getVal } from 'utils/const'

export const K = Const.of({
  CK: (() => process.env.apiKey)(),
  CK_2: (() => process.env.apiKey2)(),
  CK_3: (() => process.env.apiKey3)(),
  CK_4: (() => process.env.apiKey4)(),
  CK_5: (() => process.env.pdfKey)()
})

const CK_1 = getVal('CK')(K)
const CK_2 = getVal('CK_2')(K)
const CK_3 = getVal('CK_3')(K)
const CK_4 = getVal('CK_4')(K)
const CK_5 = getVal('CK_5')(K)

const k = {
  1: CK_1,
  2: CK_2,
  3: CK_3,
  4: CK_4,
  5: CK_5
}

export default k
