import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.value = state.value ? { ...state.value, ...payload } : payload
    }
  }
})

export const { setPage } = slice.actions

export default slice.reducer
