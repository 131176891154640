import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    current: 0,
    start: false,
    tutorial: false,
    close: false
  }
}

export const slice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setTutorial: (state, { payload }) => {
      state.value = state.value ? { ...state.value, ...payload } : payload
    }
  }
})

export const { setTutorial } = slice.actions

export default slice.reducer
