import React, { useState, useEffect, memo } from 'react'
import { useMemo } from 'use-memo-one'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'

import { MAIN_WRAPPER, TYPOGRAPHY_9 } from 'theme/theme'
import { rgba } from 'utils/colors'
import useMedia from 'utils/hooks/useMedia'
import { isCompliance, isBankAdmin } from 'utils/utils'
import { selectAll } from 'store/selectors'

export const MainWrapper = styled.div`
  ${MAIN_WRAPPER}
  padding-left: calc(250px + 40px);
  width: calc(250px + 40px * 2 + 1060px);
  max-width: 100%;
  @media (max-width: 1199px) {
    padding: 32px 18px;
    padding-left: calc(40px);
  }
  @media (max-width: 767px) {
    padding: 30px 12px;
  }
`

const IconBox = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background: ${props => props.bg ? rgba(props.bg, props.noTransparent ? 1 : 0.2) : rgba('#80FFDB', 0.2)};
  opacity: ${props => props.opacity || 1};
  border-radius: ${props => props.rounded ? '50%' : '5px'};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Picture = styled.img`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: ${props => props.rounded ? '50%' : '5px'};
  background: ${props => props.bg ? rgba(props.bg, props.noTransparent ? 1 : 0.2) : rgba('#80FFDB', 0.2)};
  opacity: ${props => props.opacity || 1};
  @media (max-width: 767px) {
    width: 28px;
    height: 28px;
  }
`

const ActiveBox = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background: #3398CC;
  border-radius: 50%;
`

const ActiveYText = styled.div`
  ${TYPOGRAPHY_9}
  color: #fff;
`

export const IconImg = styled.img`
  display: flex;
  @media (max-width: 767px) {
    height: 18px;
  }
`

export const ProfileBox = memo(function ProfileBox () {
  const user = useSelector((state) => state.user.value)
  const profilePic = user ? user?.profilePicture : null
  const isWide = useMedia({ minWidth: 1000 })
  const compliance = isCompliance(user)
  const bank = isBankAdmin(user)

  return (
    <a href={compliance || bank ? '/admin/settings' : '/profile'}><div className="cursor-pointer">
      { profilePic
        ? <Picture src={profilePic} className="mr-20" />
        : <IconBox bg="#EFEFF2" className={!isWide ? 'mr-20' : ''} noTransparent>
            <IconImg src="/s/icons/header-person.svg" />
          </IconBox> }
    </div></a>
  )
})

const filterForCount = (d) => {
  return d?.length
    ? d.filter((i) => i?.dealId && i?.active)?.length
    : 0
}

export const NotificationsBox = memo(function NotificationsBox () {
  const [count, setCount] = useState(0)
  const notifications = selectAll('notifications')
  const filteredCount = useMemo(() => filterForCount(notifications), [notifications])

  useEffect(() => {
    if (filteredCount) setCount(filteredCount)
  }, [filteredCount])

  return (
    <a href={'/notifications'} data-test="notifications"><div className="cursor-pointer mr-10">
      <IconBox bg="#EFEFF2" noTransparent rounded>
        <IconImg src={count > 0 ? '/s/icons/header-notifications-active.svg' : '/s/icons/header-notifications.svg'} />
        { count > 0 ? <ActiveBox>
          <ActiveYText>{ count }</ActiveYText>
        </ActiveBox> : null }
      </IconBox>
    </div></a>
  )
})
