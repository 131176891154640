import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null
}

export const slice = createSlice({
  name: 'email',
  initialState,
  reducers: {
    setEmail: (state, { payload }) => {
      state.value = payload
    }
  }
})

export const { setEmail } = slice.actions

export default slice.reducer
