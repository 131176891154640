import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item?._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'spv',
  initialState: adapter.getInitialState(),
  reducers: {
    setSpv: (state, { payload }) => adapter.setOne(state, payload),
    removeSpv: (state, { payload }) => adapter.removeOne(state, payload),
    setSpvs: (state, { payload }) => adapter.setAll(state, payload),
    spvsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setSpv, removeSpv, setSpvs, spvsRemoveAll } = slice.actions

export default slice.reducer
