import React from 'react'

import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

const NoSsr = ({ children }) => (
  <>{ children }</>
)

NoSsr.propTypes = {
  children: PropTypes.any
}

export default dynamic(() => Promise.resolve(NoSsr), {
  ssr: false
})
