import { useRef, useEffect } from 'react'
import { useCallback } from 'use-memo-one'

export default function useAbortController () {
  const ref = useRef()
  const isSupported = () => Object.prototype.hasOwnProperty.call(window, 'AbortController')
  const noOp = () => null
  const init = () => isSupported() ? new AbortController() : { abort: noOp, signal: {} }

  const getAbortController = useCallback(() => {
    if (!ref.current) ref.current = init()
    return ref.current
  }, [])

  useEffect(() => {
    return () => getAbortController()?.abort()
  }, [getAbortController])

  const getSignal = useCallback(() => getAbortController()?.signal, [getAbortController])

  return { getSignal, getAbortController }
}
