/* eslint-disable react/display-name */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'

import Header from 'components/layouts/header'
import ContentBox from 'components/layouts/components/contentBox'
import Meta from 'components/layouts/meta'
import NoSsr from 'components/utils/noSsr'
import { MAIN_WRAPPER } from 'theme/theme'
const Loader = dynamic(() => import('components/utils/loaders/pageLoader'), { ssr: false })
const Sidebar = dynamic(() => import('components/layouts/sidebar'), { ssr: false })

const MainWrapper = styled.div`
  ${MAIN_WRAPPER}

  padding-left: ${props => props.noSidebar ? '40px' : 'calc(250px + 40px)'};
  margin: ${props => props.noSidebar ? '0 auto' : null};
  width: ${props => props.noSidebar ? 'calc(40px * 2 + 1060px)' : 'calc(250px + 40px * 2 + 1060px)'};
  max-width: 100%;
  margin-bottom: 250px;

  @media (max-width: 1199px) {
    padding: 32px 18px;
    padding-left: ${props => props.noSidebar ? '18px' : 'calc(250px + 18px)'};
  }

  @media (max-width: 767px) {
    padding: 30px 12px;
  }
`

export function MicroLayout ({ children }) {
  return (
    <>
      <Meta title="Loading..." path="/"description="Loading..." />
      <MainWrapper noSidebar={true}>
        { children }
      </MainWrapper>
    </>
  )
}

MicroLayout.propTypes = {
  children: PropTypes.any
}

function Content ({ children, setOpen, open, setTutorialDone, noSidebar, showSidebar, onShowSidebar, doShowTutorial }) {
  return (
    <>
      { noSidebar
        ? null
        : <Sidebar
            showSidebar={showSidebar}
            onShowSidebar={onShowSidebar}
            doShowTutorial={doShowTutorial}
            setTutorialDone={setTutorialDone}
            setOpen={setOpen}
            open={open} /> }
        <MainWrapper noSidebar={noSidebar}>
           { children }
        </MainWrapper>
    </>
  )
}

const Layout = ({ children, setOpen, open, setTutorialDone, title, image, description, path, loading, noSsr, noSidebar, doShowTutorial }) => {
  [Layout.showSidebar = '', Layout.setShowSidebar = ''] = useState(false)

  return (
    <>
      <Meta title={title} path={path} image={image} description={description} />
      { noSsr
        ? <NoSsr>
            { loading
              ? <Loader />
              : <Content
                  noSidebar={noSidebar}
                  showSidebar={Layout.showSidebar}
                  doShowTutorial={doShowTutorial}
                  setOpen={setOpen}
                  open={open}
                  setTutorialDone={setTutorialDone}
                  onShowSidebar={Layout.setShowSidebar}>
                  { children }
                </Content>}
          </NoSsr>
        : loading ? <Loader /> : <Content noSidebar={noSidebar} showSidebar={Layout.showSidebar} onShowSidebar={Layout.setShowSidebar}>{ children }</Content>}
    </>
  )
}

Layout.Header = ({ children, sTitle, withBack, noProfile, Icon }) => <Header
  sTitle={sTitle}
  withBack={withBack}
  noProfile={noProfile}
  onShowSidebar={Layout.setShowSidebar}
  Icon={Icon}>{ children }</Header>

Layout.Main = ({ CB, children }) => (
  <main>
    { CB
      ? <ContentBox>{ children }</ContentBox>
      : children }
  </main>
)

export default Layout

Content.propTypes = {
  children: PropTypes.any,
  noSidebar: PropTypes.bool,
  showSidebar: PropTypes.bool,
  doShowTutorial: PropTypes.bool,
  setTutorialDone: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  onShowSidebar: PropTypes.any
}

Layout.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
  noSsr: PropTypes.any,
  description: PropTypes.string,
  image: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  doShowTutorial: PropTypes.bool,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
  setTutorialDone: PropTypes.func,
  noSidebar: PropTypes.bool
}

Layout.Header.propTypes = {
  children: PropTypes.any,
  sTitle: PropTypes.string,
  withBack: PropTypes.bool,
  Icon: PropTypes.any,
  noProfile: PropTypes.bool
}

Layout.Main.propTypes = {
  children: PropTypes.any,
  CB: PropTypes.bool
}
