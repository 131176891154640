import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item._id,
  sortComparer: false
})

export const slice = createSlice({
  name: 'businesses',
  initialState: adapter.getInitialState(),
  reducers: {
    setBusiness: (state, { payload }) => adapter.setOne(state, payload),
    removeBusiness: (state, { payload }) => adapter.removeOne(state, payload),
    setBusinesses: (state, { payload }) => adapter.setAll(state, payload),
    businessesRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setBusinesses, businessesRemoveAll, setBusiness, removeBusiness } = slice.actions

export default slice.reducer
