import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

export const adapter = createEntityAdapter({
  selectId: (item) => item._id,
  sortComparer: false
})

export const dealsSlice = createSlice({
  name: 'investorDeals',
  initialState: adapter.getInitialState(),
  reducers: {
    setInvestorDeal: (state, { payload }) => adapter.setOne(state, payload),
    removeInvestorDeal: (state, { payload }) => adapter.removeOne(state, payload),
    setInvestorDeals: (state, { payload }) => adapter.setAll(state, payload),
    setManyInvestorDeals: (state, { payload }) => adapter.setMany(state, payload),
    investorDealsRemoveAll: (state, { payload }) => adapter.removeAll(state, payload)
  }
})

export const { setInvestorDeals, setManyInvestorDeals, investorDealsRemoveAll, setInvestorDeal, removeInvestorDeal } = dealsSlice.actions

export default dealsSlice.reducer
